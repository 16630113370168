
var hamburger = {
    init: function() {
        jQuery( document ).on('click', '.js-hamburger', function (e) {
            jQuery(".header__nav").toggleClass("active");
            jQuery( this ).toggleClass('active' );
            jQuery( this ).toggleClass( 'navigation-opened');
            jQuery("body, html").toggleClass("overflow");


        } );

    }
}

export {
    hamburger
};