
import { hamburger } 	from './theme/components/hamburger.js';

window.theme = {	
	init: function () {
		var self = this;

		hamburger.init();
	},

	onloadClass: function () {
		document.querySelector('body').classList.add('loaded');
	},

	funkce: function () {
		var self = this;
	},

	windowPosition: function () {
		return {
			x: window.pageXOffset || document.documentElement.scrollLeft,
			y: window.pageYOffset || document.documentElement.scrollTop,
		};
	},
	windowSizes: function () {
		return {
			width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
			height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
		};
	},
	onresize() {
		var self = this;

	},
	onscroll(position, previusPosition) {
		var self = this;
		var currentScroll = self.windowPosition()['y'];

	},
};

jQuery(document).ready(function ($) {
	theme.init();
});

window.addEventListener('scroll', function (e) {
	theme.onscroll();
});

window.addEventListener('resize', function (e) {
	theme.onresize();
});

window.onfocus = function () { 
	
}; 

window.onblur = function () { 
	 
}; 